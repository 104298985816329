import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Contact = () => {
    return (
        <>
            <Navbar />
            <section class="text-gray-600 body-font relative">
              <div class="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
                <div class="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
                  <iframe width="100%" height="100%" class="absolute inset-0" frameborder="0" title="map" marginheight="0" marginwidth="0" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3580.513975071432!2d28.243260099999997!3d-26.179953700000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9517c538575b7b%3A0xb06b3f0c22c11a87!2sEastlands%20Office%20Park!5e0!3m2!1sen!2sza!4v1711432342786!5m2!1sen!2sza" style={{filter: "grayscale(1) contrast(1.2) opacity(0.4)"}}></iframe>
                  <div class="bg-white relative flex flex-wrap py-6 rounded shadow-md">
                    <div class="lg:w-1/2 px-6">
                      <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">ADDRESS</h2>
                      <p class="mt-1">1 Bentel Avenue, Eastlands office Park, Boksburg</p>
                    </div>
                    <div class="lg:w-1/2 px-6 mt-4 lg:mt-0">
                      <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">EMAIL</h2>
                      <a class="text-orange-500 leading-relaxed">info@boisquaredeng.co.za</a>
                      <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">PHONE</h2>
                      <p class="leading-relaxed">+27 11 736 1100</p>
                      <p class="leading-relaxed">+27 73 099 6678</p>
                    </div>
                  </div>
                </div>
                <div class="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
                  <h2 class="text-gray-900 text-lg mb-1 font-medium title-font">Contact Us</h2>
                  <p class="leading-relaxed mb-5 text-gray-600">Ready to take your communication to the next level? Explore our product offerings and contact us today to learn more about how Boi Squared Engineering can help you achieve your goals.</p>
                  <div class="relative mb-4">
                    <label for="name" class="leading-7 text-sm text-gray-600">Name</label>
                    <input type="text" id="name" name="name" class="w-full bg-white rounded border border-gray-300 focus:border-orange-500 focus:ring-2 focus:ring-orange-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                  </div>
                  <div class="relative mb-4">
                    <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
                    <input type="email" id="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-orange-500 focus:ring-2 focus:ring-orange-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                  </div>
                  <div class="relative mb-4">
                    <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
                    <textarea id="message" name="message" class="w-full bg-white rounded border border-gray-300 focus:border-orange-500 focus:ring-2 focus:ring-orange-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                  </div>
                  <button class="text-white bg-orange-500 border-0 py-2 px-6 focus:outline-none hover:bg-orange-600 rounded text-lg">Send Message</button>
                  <p class="text-xs text-gray-500 mt-3">Looking forward to hearing from you.</p>
                </div>
              </div>
            </section>
            <Footer />
        </>
    );
}

export default Contact;